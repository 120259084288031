import React, { useState } from 'react';
import '../all.css';

function Projects() {
    return (
        <div>
            Projects
        </div>
    );
}

export default Projects;
